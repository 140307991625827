import ahoy from 'ahoy.js'
import React from 'react'
import { GuidebookLink } from './GuidebookLink'

type LinkType = {
  url: string
  text: string
}

export const Disclaimer = (props: { links: Array<LinkType> }) => (
  <div className="disclaimer-section">
    <div className="disclaimer">
      <span>
        Leap Fund calculator results are for informational purposes only and
        should not be considered financial advice. Coaches and clients are
        encouraged to review all available options and make decisions about
        benefits and finances that align with your client’s individual goals.{' '}
        For more information on benefits eligibility, please visit{' '}
        <Links links={props.links} />.
      </span>
    </div>

    <div className="disclaimer">
      <div>
        * Includes EITC, CTC, and CDCTC estimates, and then divides yearly
        federal taxes into an estimated monthly amount.{' '}
        <GuidebookLink
          event="Results Page - Guidebook page for taxes"
          href="/guidebook/Tax%20Features%20e3fbbce5df3744e4b24b042aa498c105.html"
        />
      </div>
      <div>
        ** Market rate is based on Fair Market Rent (FMR) combined with Cost of
        Living Index (COLI).{' '}
        <GuidebookLink
          event="Results Page - Guidebook page for market rate"
          href="/guidebook/Housing%20Features%200a54b5637ffc49dba581ae6e923a70ed.html"
        />
      </div>
    </div>
  </div>
)

const Links = ({ links }: { links: Array<LinkType> }) => {
  switch (links.length) {
    case 1:
      return <Link link={links[0]} />
    case 2:
      return (
        <>
          <Link link={links[0]} /> and <Link link={links[1]} />
        </>
      )
    default:
      throw new Error(`unexpected number of links: ${links.length}`)
  }
}

const Link = ({ link }: { link: LinkType }) => (
  <a
    href={link.url}
    target="_blank"
    rel="noopener noreferrer"
    onClick={() =>
      ahoy.track('Results Page - Benefits Eligibility for Their Area', {
        language: 'JavaScript',
      })
    }
  >
    {link.text}
  </a>
)
