import Chart from 'chart.js/auto'
import $ from 'jquery'

interface JsonData {
  [key: string]: number
}

interface CustomTooltipItem {
  dataset: {
    data?: number[] | number
  }
  dataIndex: number
}

const convertDataToChartjsFormat = (jsonData: JsonData) => {
  const labels = Object.entries(jsonData).map(
    ([key, value]) => `${key}: ${value}`
  )
  const data = Object.values(jsonData)
  return { labels, data }
}

const createPieChart = (element: HTMLElement, jsonData: JsonData) => {
  const pieData = convertDataToChartjsFormat(jsonData)
  const canvas = document.createElement('canvas')
  element.appendChild(canvas)

  new Chart(canvas, {
    type: 'pie',
    data: {
      labels: pieData.labels,
      datasets: [
        {
          data: pieData.data,
          backgroundColor: ['#FF6660', '#0063F3'],
        },
      ],
    },
    options: {
      plugins: {
        tooltip: {
          callbacks: {
            label: ({ dataset, dataIndex }: CustomTooltipItem) => {
              const data: number[] = dataset.data as number[]
              const total = data.reduce((acc, val) => acc + val)
              const currentValue = data[dataIndex]
              const percentage = ((currentValue / total) * 100).toFixed(2)
              return ` ${Math.round(parseFloat(percentage))}%`
            },
          },
        },
        legend: {
          position: 'bottom',
          onClick: () => {
            return
          },
        },
      },
    },
  })
}

$(document).ready(() => {
  $('.pie-chart-chartjs').each(function (this: HTMLElement) {
    const jsonData: JsonData = $(this).data('pie')
    createPieChart(this, jsonData)
  })
})
