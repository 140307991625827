import ahoy from 'ahoy.js'
import React from 'react'
import numeral from 'numeral'
import { GuidebookLink } from './GuidebookLink'

export function Benefits({ data }: { data: any }) {
  if (!data.bkResult)
    return (
      <div>
        missing data
        <details>
          <summary>debug</summary>
          <pre>{JSON.stringify(data, null, 2)}</pre>
        </details>
      </div>
    )

  const { calculators } = data.bkResult.response.data

  return (
    <div className="benefits">
      <details>
        <summary>
          <h4
            style={{ display: 'inline', marginBottom: 0, lineHeight: 1.5 }}
            onClick={() => {
              ahoy.track('Results Page – Healthcare Eligibility Toggle', {
                language: 'JavaScript',
              })
            }}
          >
            Healthcare Eligibility
          </h4>
        </summary>

        <div className="color-muted small pt-2">
          These are general guidelines, and are not connected to a client’s
          self-reported healthcare amount, nor is it being factored into their
          monthly net income.{' '}
          <GuidebookLink
            event="Results Page - Guidebook page for healthcare"
            href="/guidebook/Healthcare%20Eligibility%20Features%20b6fda9cd38924c779736d8d0009afcd0.html"
          />
        </div>
        <div className="benefits__line-items">
          <div className="benefits__line-item__label">
            Adult Healthcare Eligibility
          </div>
          <div className="benefits__line-item__value">
            <div>
              {calculators.healthcare.adult_health_eligibility} for{' '}
              {calculators.healthcare.adult_health_name}
            </div>
            <div>
              {numeral(calculators.healthcare.adult_health_copay).format(
                '$0,0'
              )}{' '}
              Copay
            </div>
          </div>
          <div className="benefits__line-item__label">
            Child Healthcare Eligibility
          </div>
          <div className="benefits__line-item__value">
            <div>
              {calculators.healthcare.child_health_eligibility} for{' '}
              {calculators.healthcare.child_health_name}
            </div>
            <div>
              {numeral(calculators.healthcare.child_health_copay).format(
                '$0,0'
              )}{' '}
              Copay
            </div>
          </div>
        </div>
      </details>
    </div>
  )
}
